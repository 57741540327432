import React from "react";
import skan from "../img/skan.jpg";
import plan from "../img/plan.jpg";
import druk from "../img/druk.jpg";

const Steps = () => {
  return (
    <div className="w-full py-[3rem] px-4 bg-white">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8">
        <div className="w-full shadow-xl bg-gray-300 flex flex-col p-4 my-2 rounded-lg hover:scale-105 duration-300">
          <h2 className="text-xl font-bold text-center py-4">Krok 1</h2>
          <p className="text-center text-2xl font-bold">Część kliniczna</p>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">
              Pobranie wycisku cyfrowego (skan) lub tradycyjnego
            </p>
            <p className="py-2 border-b mx-8">
              Badanie CBCT, rejestracja zgryzu
            </p>
            <p className="py-2 border-b mx-8">
              Wysyłka skanów + CBCT na{" "}
              <span className="font-bold text-[#003CDE]">
                <a href="mailto:skany@guidelab.pl">skany@guidelab.pl</a>
              </span>{" "}
              lub poprzez formularz na stronie
            </p>
          </div>
          <img
            className="w-60 mx-auto mt-2 bg-white rounded-xl"
            src={skan}
            alt="/"
          />
        </div>
        <div className="w-full shadow-xl bg-[#003377] text-white flex flex-col p-4 md:my-0 my-2 rounded-lg hover:scale-105 duration-300">
          <h2 className="text-xl font-bold text-center py-4">Krok 2</h2>
          <p className="text-center text-2xl font-bold">
            Planowanie i pozycjonowanie implantów
          </p>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">
              Przygotowanie przez GuideLab wstepnego projektu do nawigacji
              komputerowej{" "}
            </p>
            <p className="py-2 border-b mx-8">
              Konsultacja kliniczna, ewentualne poprawki w projekcie
            </p>
            <p className="py-2 border-b mx-8">Zatwierdzenie projektu</p>
          </div>
          <img
            className="w-60 mx-auto mt-2 bg-transparent rounded-xl"
            src={plan}
            alt="/"
          />
        </div>
        <div className="w-full shadow-xl bg-gray-300 flex flex-col p-4 my-2 rounded-lg hover:scale-105 duration-300">
          <h2 className="text-xl font-bold text-center py-4">Krok 3</h2>
          <p className="text-center text-2xl font-bold">
            Produkcja i wysyłka szablonu
          </p>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">Wydruk szablonu</p>
            <p className="py-2 border-b mx-8">
              Weryfikacja, kontrola jakości, wprowadzenie tulei
            </p>
            <p className="py-2 border-b mx-8">Wysyłka do kliniki</p>
          </div>
          <img
            className="w-60 mx-auto mt-14 bg-white rounded-xl"
            src={druk}
            alt="/"
          />
        </div>
      </div>
    </div>
  );
};

export default Steps;
