import React from "react";
import logoColor from "../img/logo-color.png";
import { FaInstagram, FaTiktok } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-2 gap-1 text-gray-300">
      <div>
        <img src={logoColor} alt="GuideLasb" className="w-60" />
        <p className="py-4">
          GuideLab to partner, na którym możesz polegać w dziedzinie
          implantologii i produkcji precyzyjnych szablonów chirurgicznych. Nasza
          misja to zapewnienie najlepszej jakości usług i dostarczenie narzędzi,
          które pomogą lekarzom dentystom osiągnąć najlepsze wyniki i zapewnić
          pacjentom bezpieczne i skuteczne leczenie. Odwiedź nasze social media:
        </p>
        <div className="flex justify-around md:w-[95%] mx-auto my-6">
          <a href="https://www.instagram.com/guidelab.pl/" target="_blank">
            <FaInstagram size={30} />
          </a>
          <a href="https://www.tiktok.com/@guidelab" target="_blank">
            <FaTiktok size={30} />{" "}
          </a>
        </div>
      </div>

      <div id="footer" className="ml-5 mt-5">
        <h6 className="font-medium text-gray-400">Kontakt</h6>
        <ul>
          <li className="py-2 text-sm">Mobile: +48 506 322 471</li>
          <li className="py-2 text-sm">
            Email: kontakt@guidelab.pl / skany@guidelab.pl
          </li>
          <li className="py-2 text-sm">
            Wyspiańskiego 6a
            <br />
            95-015 Głowno
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
