import React from "react";
import cbct from "../img/1.jpg";

function scrollToOrder(event) {
  // event.preventDefault();
  document.querySelector("#dentist").click();
}
const Instruction = () => {
  return (
    <div id="dentistSection" className="w-full bg-white py-16 px-4 ">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <img className="w-[500px] mx-auto my-4" src={cbct} alt="/" />
        <div className="flex flex-col mx-2 justify-center">
          <p className="text-[#003377] font-bold">DLA DENTYSTY</p>
          <h1 className="md:text-5xl sm:text-3xl text-2xl font-bold py-2">
            Wspólnie zadbajmy o dobro Twojego pacjenta
          </h1>
          <p className="md:text-xl mt-2">
            GuideLab to nowoczesne laboratorium stomatologiczne specjalizujące
            się w produkcji precyzyjnych szablonów chirurgicznych dla
            implantologii. Nasz zespół techników dentystycznych oraz
            konsultantów klinicznych wykorzystuje najnowocześniejsze technologie
            i najwyższej jakości materiały, aby zapewnić najwyższą jakość usług.
            Naszym celem jest ułatwienie pracy lekarzom dentystom poprzez
            dostarczenie im narzędzi, które pomogą osiągnąć najlepsze wyniki i
            zapewnić pacjentom bezpieczne i skuteczne leczenie
            implantoprotetyczne.
          </p>
          <button
            onClick={scrollToOrder}
            className=" bg-black text-[#009CDE] cursor-pointer hover:scale-110 hover:bg-slate-900 duration-300 w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3"
          >
            ZAMÓW SZABLON
          </button>
        </div>
      </div>
    </div>
  );
};

export default Instruction;
