import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

const OrderForm = () => {
  const [sameAsBilling, setSameAsBilling] = useState(true);
  const form = useRef();
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm(
        "service_st4yfzl",
        "template_3jiedpt",
        form.current,
        "mo9uys3em44i843XO"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          setIsSent(true);
          setIsSending(false);
          alert("✅ Sukces! Zamówienie złożone!");
        },
        (error) => {
          console.log(error.text);
          setIsSending(false);
        }
      );
  };

  const handleCheckboxChange = () => {
    setSameAsBilling(!sameAsBilling);
  };

  return (
    <div className="container mx-auto mt-10 max-w-[60rem] px-4">
      <h2 className="text-2xl font-bold mb-6 text-white">
        Formularz zamówienia
      </h2>
      <form ref={form} onSubmit={sendEmail}>
        <div className="mb-6">
          <h3 className="text-lg font-bold mb-2 text-white">Zamówienie</h3>

          <textarea
            placeholder="Wpisz swoją wiadomość, dodaj linki z CBCT i skanami np. z Google Drive, WeTrasfer etc."
            id="orderDetails"
            name="orderDetails"
            rows="4"
            className="w-full px-4 py-2 border border-gray-300 rounded-md text-black resize-none"
            required
          ></textarea>
        </div>
        <div className="mb-6">
          <h3 className="text-lg font-bold mb-2 text-white">Dane kontaktowe</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="mb-4">
              <input
                placeholder="Imię i Nazwisko"
                type="text"
                id="fullName"
                name="fullName"
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                required
              />
            </div>
            <div className="mb-4">
              <input
                placeholder="Adres e-mail"
                type="email"
                id="email"
                name="email"
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                required
              />
            </div>
            <div className="mb-4">
              <input
                placeholder="Numer telefonu"
                type="tel"
                id="phone"
                name="phone"
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                required
              />
            </div>
          </div>
        </div>

        <div className="mb-6">
          <h3 className="text-lg font-bold mb-2 text-white">Dane do faktury</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="mb-4">
              <input
                placeholder="Imię i Nazwisko"
                type="text"
                id="billingFullName"
                name="billingFullName"
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                required
              />
            </div>
            <div className="mb-4">
              <input
                placeholder="Nazwa firmy/gabinetu"
                type="text"
                id="companyName"
                name="companyName"
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                required
              />
            </div>
            <div className="mb-4">
              <input
                placeholder="NIP"
                type="text"
                id="nip"
                name="nip"
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
              <input
                placeholder="Ulica i numer"
                type="text"
                id="billingAddress"
                name="billingAddress"
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                required
              />
            </div>
            <div className="mb-4">
              <input
                placeholder="Numer mieszkania/lokalu"
                type="text"
                id="billingApartment"
                name="billingApartment"
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
              <input
                placeholder="Kod pocztowy"
                type="text"
                id="billingPostalCode"
                name="billingPostalCode"
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                required
              />
            </div>
            <div className="mb-4">
              <input
                placeholder="Miasto"
                type="text"
                id="billingCity"
                name="billingCity"
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <input
              type="checkbox"
              id="sameAsBilling"
              name="sameAsBilling"
              checked={sameAsBilling}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="sameAsBilling" className="ml-2 text-white">
              Dane do wysyłki takie same jak dane do faktury
            </label>
          </div>
          {!sameAsBilling && (
            <div>
              <h3 className="text-lg font-bold mb-2 text-white">
                Dane do wysyłki
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="mb-4">
                  <input
                    placeholder=" Imię i Nazwisko"
                    type="text"
                    id="shippingFullName"
                    name="shippingFullName"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    placeholder="Nazwa firmy"
                    type="text"
                    id="shippingCompanyName"
                    name="shippingCompanyName"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="mb-4">
                  <input
                    placeholder="Ulica i numer"
                    type="text"
                    id="shippingAddress"
                    name="shippingAddress"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    placeholder="Numer mieszkania/lokalu"
                    type="text"
                    id="shippingApartment"
                    name="shippingApartment"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="mb-4">
                  <input
                    placeholder="Kod pocztowy"
                    type="text"
                    id="shippingPostalCode"
                    name="shippingPostalCode"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    placeholder="Miasto"
                    type="text"
                    id="shippingCity"
                    name="shippingCity"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md text-black"
                    required
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <input
          type="checkbox"
          name="check"
          required
          className="mx-3 my-2 w-5 h-4"
        />
        <label htmlFor="check" className="text-sm ">
          Oświadczam iż zapoznałem/am się z Regulaminem, Polityką Prywatności i
          cookies oraz przechowywania danych osobowych GuideLab.
        </label>
        <br />
        <br />
        <button
          type="submit"
          className="px-6 py-3 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600 mb-5"
          disabled={isSending}
        >
          {isSending ? "Trwa wysyłanie..." : "Złóż zamówienie"}
        </button>
      </form>
    </div>
  );
};

export default OrderForm;
