import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const Newsletter = () => {
  const form = useRef();
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm(
        "service_st4yfzl",
        "template_da9bomo",
        form.current,
        "mo9uys3em44i843XO"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          setIsSent(true);
          setIsSending(false);
          alert("✅ Sukces! Wiadomość wysłana");
        },
        (error) => {
          console.log(error.text);
          setIsSending(false);
        }
      );
  };

  return (
    <div className="w-full py-16 text-white px-4">
      <div className="max-w-[1240px] mx-auto grid">
        <div className="my-4">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Chcesz poznać ofertę wraz z cennikiem?
          </h1>
          <p>
            Zostaw swój adres mailowy, odezwiemy się tak szybko, jak to będzie
            możliwe.
          </p>
        </div>
        <div className="my-4">
          <form
            ref={form}
            onSubmit={sendEmail}
            className="flex flex-col md:flex-row items-center justify-between w-full"
          >
            <input
              name="user_mail"
              className="p-3 flex w-full rounded-md text-black"
              type="email"
              maxLength="50"
              placeholder="Wpisz swój adres Email"
              required
            />
            <input
              name="user_number"
              className="p-3 flex w-full rounded-md text-black m-2"
              type="tel"
              maxLength="15"
              placeholder="Numer telefonu"
            />
            <input
              type="checkbox"
              name="check"
              required
              className="mx-3 my-2 w-5 h-5"
            />
            <label htmlFor="check" className="text-sm">
              Oświadczam iż zapoznałem/am się z Regulaminem, Polityką
              Prywatności i cookies oraz przechowywania danych osobowych
              GuideLab.
            </label>
            <button
              className="bg-[#009CDE] rounded-md font-medium md:w-[600px] md:ml-4 my-6 px-6 py-3 hover:scale-105 duration-300"
              disabled={isSending}
            >
              {isSending ? "Trwa wysyłanie..." : "Chcę poznać ofertę"}
            </button>
          </form>
          <p className="text-xs">
            Dbamy o bezpieczeństwo Twoich danych. Przeczytaj naszą{" "}
            <span className="text-[#009CDE]">politykę prywatności.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
