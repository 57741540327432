import React, { useState } from "react";
import logo from "../img/logo.png";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setNav(true); // Ukryj menu rozwijane po kliknięciu
  }

  function scrollToDentistSection(event) {
    event.preventDefault();
    const section = document.querySelector("#dentistSection");
    section.scrollIntoView({ behavior: "smooth" });
    setNav(true); // Ukryj menu rozwijane po kliknięciu
  }

  function scrollToContactSection(event) {
    event.preventDefault();
    const section2 = document.querySelector("#footer");
    section2.scrollIntoView({ behavior: "smooth" });
    setNav(true); // Ukryj menu rozwijane po kliknięciu
  }

  function scrollToOrderSection(event) {
    event.preventDefault();
    const orderBtn = document.querySelector("#dentist");
    orderBtn.click();
    setNav(true); // Ukryj menu rozwijane po kliknięciu
  }

  return (
    <div className="fixed z-50 top-0 left-0 right-0 bg-black">
      <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white">
        <img
          src={logo}
          alt="GuideLasb"
          className="w-60 cursor-pointer"
          onClick={scrollToTop} // Dodaj obsługę przewijania do góry po kliknięciu w logo
        />
        <ul className="hidden md:flex uppercase font-bold">
          <li
            onClick={scrollToDentistSection}
            className="p-4 hover:bg-[#003CDE] rounded-full cursor-pointer"
          >
            Dla dentysty
          </li>
          <li
            onClick={scrollToContactSection}
            className="p-4 hover:bg-[#003CDE] rounded-full cursor-pointer"
          >
            Kontakt
          </li>
          <li
            onClick={scrollToOrderSection}
            className="p-4 hover:bg-gray-800 rounded-full cursor-pointer"
          >
            Zamów szablon
          </li>
        </ul>
        <div onClick={handleNav} className="block md:hidden">
          {!nav ? <AiOutlineClose size={25} /> : <AiOutlineMenu size={25} />}
        </div>
        <div
          className={
            !nav
              ? "fixed left-0 top-[96px] w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500"
              : "fixed top-[96px] left-[-100%] ease-in-out duration-500"
          }
        >
          <ul className="uppercase p-4">
            <li
              onClick={scrollToDentistSection}
              className="p-4 border-b border-gray-600"
            >
              Dla dentysty
            </li>
            <li className="p-4 border-b border-gray-600">
              <a href="#footer" onClick={scrollToContactSection}>
                Kontakt
              </a>
            </li>
            <li onClick={scrollToOrderSection} className="p-4 ">
              Zamów szablon
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
